.book-consultation {
    %bookConsNormalizeWrap {
        margin-bottom: 20px;
        @include respondTo(screensmmax) {
            margin-bottom: 10px;
        }
        display: flex;
        align-items: flex-end;
        border-bottom:1px solid transparentize($grey, .8);
        color:white;
    }
    %bookConsNormalizeIn {
        appearance: none;
        color: white;
        font-size: 14.4px;
        @include respondTo(screensmmax) {
            font-size: 12px;
        }
        font-family: $fontFamily !important;
        font-weight: 300;
        display: block;
        height:20px;
        &:focus {
            outline-color: $yellow;
        }
        width: 100%;
        background-color: transparent;
        border:0;
    }
    .input-wrap {
        width: calc(50% - 4px);
        @include respondTo(screensmmax) {
            width: 100%;
        }
        @extend %bookConsNormalizeWrap;
        input {
            @extend %bookConsNormalizeIn;
            @include placeholder {
                color: white;
            }
        }
    }
    .checkbox-wrap {
        width: 100%;
        @extend %bookConsNormalizeWrap;
        justify-content: space-between;
        @include respondTo(screensmmax) {
            flex-direction: column;
            legend {
                margin-bottom: 10px;
                width: 100%;
            }
        }
        @include respondTo(screenxxlgmin) {
            font-size: 14.4px;
        }
        .checkbox-list {
            position: relative;
            width: calc(50% - 4px);
            @include respondTo(screensmmax) {
                width: 100%;
            }
            list-style: none;
            padding-left:0;
            li {
                display: inline-block;
                label {
                    position: relative;
                    cursor: pointer;
                    i {
                        @include square(10px);
                        display: inline-block;
                        margin-right: 5px;
                        border: 1px solid $yellow;
                        border-radius: 100%;
                        @include transitions;
                    }
                    input {
                        position: absolute;
                        opacity: 0;
                        &:checked {
                            &+i {
                                background-color: $yellow;
                            }
                        }
                    }
                }
            }
            .shadow {
                @extend %fullAbs;
                width: 100%;
                background-color: $dark;
                color: white;
                border: none;
                text-align: left;
                font-family: $fontFamily;
                font-size: .8rem;
                font-weight: 300;
                padding-bottom: 2px;
                i {
                    display: inline-block;
                    width: 5px;
                    margin-left: 5px;
                    img {
                        max-width: 100%;
                    }
                }
                @include respondTo(screensmmin) {
                    display: none;
                }
            }
            &:hover {
                .shadow {
                    display: none;
                }
            }
        }
    }
    .textarea-wrap {
        @extend %bookConsNormalizeWrap;
        width: 100%;
        textarea {
            @extend %bookConsNormalizeIn;
            height: 80px;
            resize: none;
            color: white;
            @include placeholder {
                color: white;
            }
        }
    }
    input[type="Submit"] {
        margin-top: 20px;
        @extend %bookConsNormalizeIn;
        text-align: left;
        color: $yellow;
        border-bottom: 1px solid $yellow;
        cursor: pointer;
    }
}