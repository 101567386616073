.preheader{
	position: absolute;
	left: 0;
	right: 0;
	height: 0;
	z-index: -1;
	visibility: hidden;
	@include respondTo(screenlgmax){
		visibility: visible;
	}
	
	&.is-open{
		position:fixed;
		top: 0;
		width: 100%;
		height: 100vh;
		z-index: 5;
		@include respondTo(screenmdmax){
			overflow-y: scroll;
		}
	}
	&__body{		
		padding : {
			top:150px;
			left:20px;
			bottom:125px;
			right:20px;
		}
	}

	.preheader-navigation{
		position: relative;
		z-index: 1;
		@extend %listNormalize;
		text-align: right;
		li{
		a{
			position: relative;
			display: inline-block;	
			padding: 	10px 40px;
			font-size: 	.95rem;
			
			text-transform: uppercase;	
			@include links(
				white,
				$yellow
			);
			&:after{
				@include transitions;
				position: absolute;
				top:calc(50% - 1px);
				right:0;
				content:"";
				width:20px;
				height: 0;
				background-color: white;
				border:.5px solid white;
				
			}
			&.current{
				color:$yellow;
				&:after{
					background-color: $yellow;
					border:.5px solid $yellow;
				}
			}
		}
	}
	&--socials{
		a{
			color:$yellow !important;
			&:after{
					background-color: $yellow !important;
					border:.5px solid $yellow !important;
				}
			}
		}
	}

}

.header{
	position: absolute;	
	top: 0;	
	left: 0;
	right: 0;
	z-index: 5;
	height:95px;
	.consultation-button{
		font-size: 	.9rem;
		display: inline-flex;
		@include respondTo(screenlgmax){
		display: none !important;
	}
}
	.hamburger{
		display: none;
		@include respondTo(screenlgmax){
			position: relative;
			display: flex;
		}
	}

}

.header-logo{
	max-width: 	230px;
	display: inline-block;
	@include respondTo(screenmdmax){
		max-width: 140px;
	}	
	img{
		max-width: 	100%;
		width: 	100%;
	}
}

.header-navigation{
	display: flex;
	justify-content: center;
	
	
	@include respondTo(screenlgmax){
		display: none !important;
	}
	@extend %listNormalize;
	@extend %listInline;
	flex:1;
	li{
		a{
			display: inline-block;	
			padding: 	10px 12px;
			font-size: 	.9rem;
			@include respondTo(screenxlgmax){
				padding: 	10px 6px;
				font-size: 	.85rem;
			}
			text-transform: uppercase;	
			@include links(
				white,
				$yellow
			);
			&.current{
				color:$yellow;
			}
		}
	}
}