%circleDots{	
	li{
		display: inline-block;
		margin-left: 5px;
		margin-right: 5px;
		button{
			@include square(8px);
			text-indent: -9999px;
			border: 1px solid $yellow;
			background-color: transparent;
			border-radius:100%;
			appearance:none;
			cursor: pointer;
		}
		&.slick-active{
			button{
				border: 1px solid $yellow;
				background-color: $yellow;
			}
		}
	}
}



.slider-timer-section{	
	.slider-timer{
		min-height: 500px;
		height:60vw;
		max-height:1550px;	
		text-align: center;
		&__item{
			min-height: 500px;
			height:60vw;
			max-height:1550px;	
		}
	}

	.slick-dots{
		position: absolute;
		bottom:20px;
		z-index: 3;
		left:50%;
		margin:0;
		transform: translateX(-50%);
		@extend %listNormalize;
		@extend %circleDots;
	}

	// .slick-list{padding:0 20% 0 0;}

	/* progress bar */
	.slider-progress {
		position: absolute;
		z-index: 3;
		bottom:60px;
		width: 10%;
		height: 1px;
		background: #eee;
		left:50%;
		margin:0;
		transform: translateX(-50%);
		.progress {
			width: 0%;
			height: 1px;
			background-color: $yellow;
		}
	}
}
	
.highlights-bar{
		&__item{
			background-color: white;
			padding: 20px 20px 20px 15px;
			height: 100%;
		}
		.slick-track{
		    display: flex;
		    height: 100%;
		}

		.slick-prev{
			display: none !important;
		}

		.slick-next{
			position: absolute;	
			z-index: 2;
			bottom:10px;
			right:calc(20% + 20px);
			@include respondTo(screenmdmin){
				right:calc(0% + 20px);
			}
			text-indent: -9999px;
			width:32px;
			height:20px;
			border:0;
			appearance:none;
			cursor: pointer;	
			background : {
				color: transparent;
				image:url(../img/arrow-next-mob.svg);
				size:contain;
				repeat: no-repeat;
				position: center;			
			}
		}

		.slick-slide {
		    margin-left: 7.5px;
		    margin-right: 7.5px;
		    display: flex;
        	height: auto;
		}
		
		.slick-list {
		    margin-left: -7.5px;
		    margin-right: -7.5px;	
		    height: 100%;   
@include respondTo(screenmdmax){
			padding:0 20% 0 0;	
		}
	}
}


.history-slider{
	position: relative;	
	min-height: 815px;
	height:90vh;
	&__item{
		min-height: 680px;
		height:90vh;
		padding-left: 110px;
		.history-icon{
			display: block;	
			max-width: 	135px;
			&--smaller{
				max-width: 	85px;
			}
			padding: 20px 0;
			img{
				max-width: 	100%
			}
		}
		.item-body{
			height:100%;
			display: flex;	
			flex-direction: column;	
			padding-bottom: 70px;
			&__main{
				padding: 35px 45% 35px 5%;
				margin-left: 110px;
				flex:1;
				border-left: 1px solid $yellow;
			}
			&--invert{
				padding-top: 70px;
				padding-bottom: 0;
			}
		}
	}
	.slick-list{
		padding:0 40% 0 0;
	}

	.slick-prev{
		display: none !important;	
	}
	.slick-next{
		position: absolute;	
		z-index: 2;
		top:20%;
		right:5%;
		text-indent: -9999px;
		width:83px;
		height:30px;
		border:0;
		appearance:none;
		cursor: pointer;	
		background : {
			color: transparent;
			image:url(../img/arrow-next.svg);
			size:contain;
			repeat: no-repeat;
			position: center;			
		}
	}
	.activate {	
		opacity: 1;	
	}
	.animated {	
		opacity: 0;
	}	
}

.testimonial-slider{
	padding-top: 60px;
	padding-bottom: 30px;
	@include respondTo(screenmdmin){
		padding-top: 120px;
		padding-bottom: 120px;		
	}
	@include respondTo(screenlgmin){
		padding-top: 180px;
		padding-bottom: 180px;		
	}
	@include respondTo(screenxlgmin){
		padding-top: 200px;
		padding-bottom: 200px;		
	}
	position: relative;
	.left-side{
		padding: 50px 3rem 20px 3rem;
		@include respondTo(screenmdmin){
			padding: 50px 20px 20px 15px;	
		}
		@include respondTo(screenlgmin){
			padding: 50px 20px 20px 130px;	
		}
		
		.logo-wrap{
			display: block;
			max-width: 200px;
			@include respondTo(screenmdmax){
			max-width: 110px;
		}
			img{
				max-width:100%;
			}
		}
	}
	.right-side{
		padding: 50px calc(3rem - 15px) 80px calc(3rem - 15px);

		@include respondTo(screenmdmin){
			padding: 50px 0 80px;	
		}		
		
	}

	.slick-prev{
		display: none !important;	
	}
	.slick-next{
		position: absolute;
		bottom:0;
		border: 0;
		appearance:none;
		display: flex;
		align-items: center;
		justify-content: space-between;
		bottom: 30px;
		left: calc(3rem - 15px);
	@include respondTo(screenmdmin){		
		bottom: 120px;		
		left:calc(50% + 8.3333% + 20px);
	}
	@include respondTo(screenlgmin){	
		bottom: 180px;
		left:calc(50% + 8.3333% + 20px );		
	}
	@include respondTo(screenxlgmin){
		bottom:200px;
	}		
		cursor: pointer;
		span{
			margin-right: 5px;
		}
		i{
			width:28px;
			img{
				min-width: 100%;
			}
		}
	}
	
}

.product-slider{
	position: relative;
	padding: 0 !important;
	
	.slick-list{
		height: 100%;
		.slick-track{
			height: 100%;
			.slick-slide{
				height: 100%;
				&>div{
					height: 100%;
				}
			}
		}
	}
	&__item{
		@include adMinHeight(280, 280, 32, 992, 100, 14);
		//min-height: 560px;
		height: 100%;
	}
	.slick-arrow{
		position: absolute;
		top:50%;
		transform: translateY(-50%);
		margin: 0;
		z-index: 2;
		width:25px;
		height:50px;
		text-indent: -9999px;
		font-size: 0;
		appearance:none;
		background-color: transparent;
		border:0;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		&:focus{
			outline-color: $yellow !important;
		}
		&:before{
			content:"";
			display: block;
			width:16px;
			height:29px;

			background : {				
				repeat:no-repeat;
				size: 16px 29px;
			};
			
		}
		&.slick-prev{
			left:20px;
			&:before{
				background-image: url(../img/arrow-prev-small.svg);
			}
		}
		&.slick-next{
			right:20px;
			&:before{
				background-image: url(../img/arrow-next-small.svg);
			}
		}
	}
	.slick-dots{
		display: flex;
		justify-content: center;
		align-items: center;
		position: absolute;
		left:50%;
		bottom:25px;
		transform: translateX(-50%);
		margin: 0;
		@extend %circleDots;
		li{
			@include square(6px);
			button{
				@include square(6px);
				border: 1px solid white;
				background-color: white;

			}
			&.slick-active{
				width:20px;
				height:14px;
				display: flex;
				align-items: center;
				justify-content: center;
				overflow: hidden;
				button{
					animation: dots .5s 0s 1 alternate backwards;
					width:32px;
					height:20px !important;
					border: 0;
					background : {
						color:transparent;				
						repeat:no-repeat;
						size: 16px 10px;
						image: url(../img/focus.svg);
						position: center;
					
					};
				}
			}
		}
	}
}


.process-slider{
		&__item{
			background-color: white;
			padding: 20px 20px 15px 15px;
			height: 100%;
			.item-body{
				display: flex;
				height: 100%;
				flex-direction: column;
				justify-content: space-between;
			}
			.text-side{
				padding-top: 5px;
				padding-bottom: 20px;
				display: flex;
				flex:1;
				flex-direction: column;
				justify-content: space-between;
			}
			.image-side{
				width: 100%;
				padding-bottom: 20px;
				min-height: 215px;
    			height: 10vw;		
				display: flex;
				align-items: flex-end;
				.logo-wrap{
					width: 50px;					
					img{
						min-width: 100%;
					}
				}
			}
		}
		.slick-track{
		    display: flex;
		    height: 100%;
		}
		.slick-next, .slick-prev {
			position: absolute;	
			z-index: 2;
			top: calc(50% - 30px);
			text-indent: -9999px;
			width: 30px;
    		height: 60px;
			border:0;
			appearance:none;
			cursor: pointer;
			outline: 0;
			background : {
				color: transparent;
				size:contain;
				repeat: no-repeat;
				position: center;			
			}
		}
		.slick-prev{
			left:-60px;
			background-image:url(../img/arrow-left-community.png);
		}
		.slick-next{
			right:-60px;
			background-image:url(../img/arrow-right-community.png);
			@include respondTo(screenlgmax){
				
			}
			
			
		}
		.slick-slide {
		    margin-left: 7.5px;
		    margin-right: 7.5px;
		    display: flex;
			height: auto;
			&>div{
				width: 100%;
			}
		}
		
		.slick-list {
		    margin-left: -7.5px;
		    margin-right: -7.5px;	
		    height: 100%;
			padding:0 10% 0 0;
			@include respondTo(screenlgmax){
				padding:0 20% 0 0;	
			}
		}
}

.community-slider{
	&__item{
		padding:0 120px 50px 15px;
	
		.item-body{			
			padding : $overMarginTop 55px 0 ;
		}
		.text-side{
			
			padding: 50px 60px 90px 40px;
			i{
				display: block;
				width: 110px;
				margin-top: 30px;
			}
		
		}
		.image-side{
			min-height: 480px;
			margin-bottom: -50px;
			
		}
	}
	.slick-slide {
		 
		  
        	height: auto;
		}
	.slick-list {
		
		
		padding:0 40px 0 0;	
	}

	.slick-prev{
			display: none !important;
		}
		.slick-next{
			position: absolute;	
			z-index: 2;
			top:calc(50% - 25px);
			right:calc(40px + 10px);
			text-indent: -9999px;
			width:86px;
			height:50px;
			border:0;
			appearance:none;
			cursor: pointer;	
			background : {
				color: transparent;
				image:url(../img/arrow-next.svg);
				size:contain;
				repeat: no-repeat;
				position: center;			
			}
		}
}