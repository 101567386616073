.txt-pretitle {
	font : {
		family: $fontFamily;
		size: 14px;
		weight: 700;
	}

	@include respondTo(screenxlgmin) {
		font-size: 14px
	}

}

.txt-title {
	line-height: 1;

	font : {
		family: $fontFamily;
		size: 2.7rem;
		weight: 300;
	}

	@include respondTo(screenxlgmax) {
		font-size: 2.2rem
	}

	@include respondTo(screensmmax) {
		font-size: 1.8rem
	}
	&--extended{
		line-height: 1.15;
	}

	&--thin {
		line-height: .9;
	}
}

.txt-nametitle {
	font : {
		family: $fontFamily;
		size: 20px;
		weight: 400;
	}

}

.txt-subtitle {
	font : {
		family: $fontFamily;
		size: 14px;
		weight: 300;
	}

	@include respondTo(screenxlgmin) {
		font-size: 16px
	}
}

.history-title {
	line-height: .9;
	font-weight: 300;
	font-size: 4.5vw;

	@media (min-width: 2560) {
		font-size: 126px;
	}
}

.history-year {
	line-height: .9;
	font-weight: 300;
	font-size: 20vw;

	@media (min-width: 2560) {
		font-size: 560px;
	}
}


.history-txt {
	font : {
		family: $fontFamily;
		size: 1.3vw;
		weight: 300;
	}

	@media (min-width:2560) {
		font-size: 25px;
	}
}

.txt-policy {
	p {
		margin-bottom: .5rem;
	}

	ul {
		padding-left: 20px;
	}

	a {
		@extend .link-black;
		font-weight: bold;
	}
}

.txt-process {
	line-height: 1;

	font : {
		family: $fontFamily;
		size: 2.5rem;
		weight: 300;
	}

	@include respondTo(screenxlgmax) {
		font-size: 2.2rem
	}

	@include respondTo(screensmmax) {
		font-size: 1.8rem
	}
	&--extended{
		line-height: 1.15;
	}

	&--thin {
		line-height: .9;
	}
}