.footer{
	position: relative;
	z-index:0;
	 @include respondTo(screenxxlgmin){
		    font-size:14.4px;
		  }
	
	&__body{
		padding: 5.3rem 8.75rem 10.62rem  5.94rem;
		@include respondTo(screenxlgmax){
			padding: 5.3rem 3rem;
		}
		@include respondTo(screenlgmax){
			padding: 4.3rem 1rem;
		}
		@include respondTo(screensmmax){
			padding: 3rem 3rem 2rem;
		}
	}
	.logo-wrap{
		max-width: 290px;
		@include respondTo(screenxlgmax){
			max-width: auto;
			width:290px;
		}

	}
	.footer-text{
		display: flex;
		flex-direction:column;
		b{
			font-weight: 500;
		}
		width: 750px;
		margin-right:100px;
		@include respondTo(screenxlgmax){
			width: 600px;
			margin-right:100px;
		}
		@include respondTo(screenlgmax){
			width: auto;
			max-width: 50%;
			margin-right:50px;
		}
		@include respondTo(screenmdmax){
			width: 100%;
			max-width: 100%;
			margin-right: 0;
		}

		&__head{			
				min-height: 95px;			
		}
		&__body{
			padding-left: 60px;
			padding-right: 15px;
			flex:1;
			.text-wrap{
				border-top: 1px solid transparentize($grey,.8);
				
			}
		}
	}
	.footer-mob-text{
		width: 100%;
		margin-top: 60px;
		font-size: .8rem;
		@include respondTo(screensmmax){			
			font-size: .7rem;
		}
	}
	.footer-form{
		width: 430px;
		display: flex;
		flex-direction: column;
		@include respondTo(screenmdmax){
			width: 100%;
		}

		&__head{
			@include respondTo(screenmdmin) {
				min-height: 95px;
			}
			.intro-title{
				line-height: 1;
			}
		}
		&__body{
			flex:1;
			display: flex;
			border-top: 1px solid transparentize($grey,.8);
			padding-top: 10px;
			form{
				margin-top: 10px;
				display: flex;
				flex-direction: column;
				.row:last-child{					
					flex:1;
					align-items: flex-end;					
				}
			}
		}
	}
}