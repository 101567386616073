$overMarginTop: 40px;
$overMarginBottom: 55px;
.intro-section{
	overflow: hidden;
	min-height: 500px;
	height:100vh;
	max-height:1300px;
	@include respondTo(screenmdmin){
		text-align: center;
	}
	position: relative;
    &--short {
        height: 95px;
        min-height: 0;
        max-height: none;

        .scroll-down-bttn {
            display: none
        }
    }
	&__background{
		@extend %fullAbs;
		position: absolute !important;
		z-index: 0;
		&.scale-it{
			transition: opacity 1s linear .5s, transform 1s linear 0s;
			opacity: 0;
			&-now{
				transform:scale(1.2,1.2);
				opacity: 1;
			}
		}
	}
	&__button{
		position: absolute;
		left: 0;
		right: 0;
		bottom: 35px;
		@include respondTo(screenmdmin) {
			bottom: #{$overMarginTop + 10px};
		}
		@include respondTo(screenlgmin){
			bottom: #{$overMarginTop + 35px};
		}
		z-index:2;
	}
	.scroll-down-bttn{
		@include respondTo(screenmdmin){
			margin: auto;
		}
	}

	&--free {
		height: auto;
		padding-top:50px;
		padding-bottom: 200px;
		@include respondTo(screenmdmin) {
			padding-top: 100px;

		}
		@include respondTo(screenmdmin){
			padding-top:150px;
			padding-bottom: 300px;
		}

	}
}

.loader-scope{
    position: absolute;
    display: inline-block;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%)
}

main > .loader-scope {
    background-color: $dark;
    top: 0; right: 0;
    left: 0; bottom: 0;
    z-index: 1;
    margin: auto;
    transform: none;
    display: flex;
    justify-content: center;
    align-items: center;
}

.slider-timer-section{
	position: relative;
	z-index: 2;
	min-height: 500px;
	height:60vw;
	max-height:1550px;
}

.highlights-bar-section{
	position: relative;
	z-index: 4;
	background-color: transparent;
	&.background-very-light-grey{
		background-color: $veryLightGrey !important;
	}
	&__body{
		margin-top: -$overMarginTop;
		margin-bottom: -$overMarginBottom;
		@include respondTo(screenlgmax){
		margin-top: 0;
		margin-bottom: 0;
			padding: 15px 22.5px 15px 15px;
		}

	}
	.highlights-bar-row{
		position: relative;
		z-index: 4;
		background-color: white;
		@include respondTo(screenmdmax){
			background-color: transparent;
		}
		padding : {
			top: $overMarginTop;
			bottom: $overMarginBottom;
			left: 80px;
			right: 80px;
		}
		@include respondTo(screenlgmax){
			padding: 0 15px;
		}
		@include respondTo(screenmdmax){
			padding: 0;
		}
	}
}

.image-offset-section{
	position: relative;
	z-index: 4;
	@include respondTo(screenmdmax){
		background-color: $veryLightGrey;
		padding-top: 15px;
	}
	&__body{
		margin-top: -$overMarginTop;
		padding : {
			top: $overMarginTop;
			left: 40px;
			right: 40px;
		}
		@include respondTo(screenmdmax){
			margin-top: 0;
			padding : {
				top: 35px;
				left: 0;
				right:0;
			}
			background-color: white;
		}
		.text-side{
			padding: 0 40px 30px;
			@include respondTo(screenmdmin){
			padding: 30px 20px 80px 80px;
		}
		}
		.image-side{
			min-height: 335px;
			max-height:350px;
			height: 30vw;
			margin-bottom: -50px;
			margin-top: 50px;
			@include respondTo(screenmdmax){
				margin-bottom: 0;
			}
		}
	}

}

.text-offset-section{
	padding-top: 15px;
	padding-bottom: 15px;
	position: relative;
	z-index: 4;
	@include respondTo(screenmdmax){
			background-color: $veryLightGrey;
		}
	&__body{
		@include respondTo(screenmdmax){
			background-color: white;
		}
	@include respondTo(screenmdmin){
		margin-top: -$overMarginTop;
		padding : {
			top: $overMarginTop;
			left: 40px;
			right: 40px;
			bottom: 55px;
		}
		}
		.left-side{

				padding: 30px 30px 20px 55px;

			@include respondTo(screenlgmin){
				padding: 50px 20px 20px 80px;
			}
		}
		.right-side{
			padding: 30px 30px 20px 40px;
			@include respondTo(screenlgmin){
				padding: 50px 0;
			}

		}
	}
}

.simple-section{
	padding-top: 30px;
	@include respondTo(screenmdmin){
		padding-top: 60px;
		padding-bottom: 60px;
	}
	@include respondTo(screenlgmin){
		padding-top: 100px;
		padding-bottom: 75px;
	}
	@include respondTo(screenxlgmin){
		padding-top: 115px;
	}
	.title-side{
		padding-left:80px;
	}
}

.columns-section{
	padding-top: 30px;
	@include respondTo(screenmdmin){
		padding-top: 60px;
		padding-bottom: 60px;
	}
	@include respondTo(screenlgmin){
		padding-top: 100px;
	}
	@include respondTo(screenxlgmin){
		padding-top: 115px;
	}
	&__body{
		padding-left:15px;
		padding-right:15px;
		@include respondTo(screenmdmax){
			.txt-title{
				color: $yellow;
			}
		}
		@include respondTo(screenxlgmin){
			padding-left:105px;
			padding-right:105px;

		}
	}
}

.contact-map-section{
		position: relative;
		z-index: 3;
		@include respondTo(screenmdmax){
			background-color: $veryLightGrey;
			padding-top: 15px;
		}
		@include respondTo(screenmdmin){
		padding-bottom: 40px;
	}
	&__body{
		@include respondTo(screenmdmax){
			background-color: white;
		}
	@include respondTo(screenmdmin){
		margin-top: -$overMarginTop;
	}
	}

	.text-side{
		padding: 30px 40px 30px 55px;
		@include respondTo(screenmdmin){
			padding: #{$overMarginTop + 50px} 20px 100px 80px;
		}
	}
	.iframe-container{
		min-height: 330px;
		height: 100%;
	}
}

.process-section{
	padding-top: 140px;
	padding-bottom: 40px;
	&__head{
		margin-bottom: 50px;
	}
	&__item{
		padding: 55px 40px;
		.image-side{
			min-height: 485px;
			height:33vw;
			max-height:600px;
		}
		.text-side{
			padding: 50px 20px 50px 65px;
		}
	}
}

.process-slider-section{
	padding-top: 20px;
	padding-bottom: 20px;
	&__head{
		margin-top: 10px;
		margin-bottom: 20px;
	}

}

.values-section{
	padding-top: 150px;
	padding-bottom: 80px;
	&__body{
		padding-left: 105px;
		padding-right: 105px;
	}
	.item-body{
		padding: 15px;
		.image-wrap{
			min-height: 485px;
			height:33vw;
			max-height:600px;
		}
	}
}

.promo-section{
	padding: {
        top: 50px;
        bottom: 50px
    }

    img {
        max-width: 100%;
        margin: 0 0 30px;
        display: block
    }

	@include respondTo(screenmdmin){
        padding: {
            top: 100px;
            bottom: 100px
        }

        img { margin: 0 }
	}
}

.mosaic-section{
	position: relative;
	z-index: 5;
	@include respondTo(screenmdmin){
	padding-top: 50px;
		}
	&__body{
		@include respondTo(screenmdmax){
			padding-left:15px;
			padding-right:15px;
		}
		@include respondTo(screenmdmin){
			margin-bottom: -50px;
		}
		width: 100%;
		@include respondTo(screenlgmin){
			padding-left: 135px;
			padding-right: 135px;
		}
		.mosaic-item{
			height:45vw;
			cursor: pointer;
			@include respondTo(screenmdmin){
				height:40vw;
			}
			@include respondTo(screenlgmin){
				height:30vw;
			}
			@include respondTo(screenxlgmin){
				height:28vw;
			}
			max-height: 555px;
			position: relative;
			overflow: hidden;
			&__hover {
				background-color: rgba($yellow, 0);
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				width: 100%;
				height: 100%;
				display: flex;
				justify-content: center;
				align-items: center;
				text-align: center;
				opacity: 0;
				transition: all .2s;
			}
			&__footer{
				display: none;
				@include transitions;
				position: absolute;
				left: 0;
				right: 0;
				height: 0;
				bottom:-100%;
				display: flex;
				flex-direction: column;
				justify-content: center;
				padding: 20px;
				@include respondTo(screenlgmin){
					padding: 50px;
				}
			}
			&:hover,
			&:active{
				.mosaic-item__hover {
					opacity: 1;
					transition: all .4s;
					background-color: rgba($yellow, .8);
				}
				.mosaic-item__footer{
					height:100%;
					bottom:0;
				}
			}
			.mosaic-item__footer{
				&.active{
					height:100%;
					bottom:0;
				}
			}

			.follow-link{
				font-weight: 500;
				border-bottom: 1px solid transparentize(white,.5);
				position: absolute;
				bottom:25px;
				@include respondTo(screenlgmin){
					bottom:50px;
				}
				left:50%;
				transform: translateX(-50%);
				margin: 0;
			}
		}
	}
}

.testimonial-slider-section{
	position: relative;
	.paging-info{
		position: absolute;
			bottom: 30px;
			right:3rem;
	@include respondTo(screenmdmin){
		right:calc(50% - 33.3333%);
		bottom: 120px;
	}
	@include respondTo(screenlgmin){
		bottom: 180px;
	}
	@include respondTo(screenxlgmin){
		bottom:200px;
	}
	}
}

.product-slider-section{
	padding-bottom: 90px;
	&__body{
		padding-left: 15px;
		padding-right: 15px;
	}
	.text-side{
		flex: 0 0 calc(50% - 40px);
  		max-width: calc(50% - 40px);
		margin-top: 30px;
		margin-bottom: 30px;
		padding: 50px 20px 50px 80px;
	}
	.slider-side{
		flex: 0 0 calc(50% + 40px);
  		max-width: calc(50% + 40px);
		&__body{

			height: 100%;
		}
	}
}

.under-intro{
	margin-top: -$overMarginTop;
}

.team-section{
	padding-top: 65px;
	padding-bottom: 65px;
	@include respondTo(screenmdmax){
		padding-left: 15px;
		padding-right: 15px;

	}
	&__item{
		margin-bottom: 45px;
		.img-wrap{
			margin-bottom: 15px;
			height:190px;
			@include respondTo(screenmdmin){
				margin-top: 3.5vw;
				margin-bottom: 2vw;
				height:370px;
			}
			background-size: contain;
			background-position: bottom center;
			border-bottom: 1px solid $yellow;
		}
		aside{
			height:0;
			opacity: 0;
		}
	}
}

.community-slider-section{
	padding-bottom: 100px;
	position: relative;
	z-index: 4;
	&__body{
		margin-left: -15px;
		margin-right: -15px;
	}
}
