@keyframes dots {
  from {
    transform: scale(0,0);
  }
  to {
   transform: scale(1,1);
  }
}

@keyframes fadeInRight {
  from {
    opacity: 0;   
    transform: translate3d(50px, 0, 0);
  }

  to {
    opacity: 1;    
    transform: translate3d(0, 0, 0);
  }
}

// @keyframes fadeInRight {
//   from {
//     //opacity: 0;   
//     transform: translate3d(0, 0, 0);
//   }

//   to {
//   //  opacity: 1;    
//     transform: translate3d(10px, 0, 0);
//   }
// }

// @keyframes rotateInUpLeft {
//   from {
    
//     transform-origin: left bottom;    
//     transform: rotate3d(0, 0, 1, 30deg);
//     opacity: 0;
//   }

//   to {   
//     transform-origin: left bottom;  
//     transform: translate3d(0, 0, 0);
//     opacity: 1;
//   }
// }


.title-animation{
  position: relative;
  display: none;  
  span {   
    min-width: 10px;
    min-height: 10px;
    position: relative;
  }
}




@keyframes loader {
  0% {    
    transform: rotate(0);
  }
  100% {    
    transform: rotate(360deg);
  }
}

@keyframes loader_reverse {
  0% {   
    transform: rotate(0);
  }
  100% {    
    transform: rotate(-360deg);
  }
}

.loader {
  position: relative;
  width: 200px !important;
  height: 200px !important;  
  transform: translate(-50px, -50px) scale(0.5) translate(50px, 50px);
  margin-left: 50px;
  //  i {
  //   display: block;
  //   position: absolute;
  //   width: 160px;
  //   height: 160px;
  //   top: 20px;
  //   left: 20px;
  //   border-radius: 50%;
  //   border: 6px solid black;
  //   border-color: $yellow transparent $yellow transparent;  
  //   animation: loader 1.5s linear infinite;
  //   &:nth-child(2) {
  //     width: 144px;
  //     height: 144px;
  //     top: 28px;
  //     left: 28px;
  //     border-color: transparent $grey transparent $grey;  
  //     animation: loader_reverse 1.5s linear infinite;
  //   }
  // }
  img {
    max-width: 100%;
  }
}