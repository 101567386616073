//Fonts

$fontFamily: 'Yantramanav', sans-serif;
//$fontTitle: 'Roboto Slab', serif;


//Colors

$grey: #ccc;
$yellow: #ffc012;
$darkGrey:#606060;
$dark: #231F20;
$veryLightGrey: #f1f2f2;

//responsive
$smartphone:        480px;
$tabletPortrait:    768px;
$tabletLandscape:   992px;
$ipadmax:           1025px;
$desktop:           1200px;
$desktopBig:        1440px;
$desktopVeryBig:    1600px;
$desktopSuperBig:   1880px;
