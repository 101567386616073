.color {
	&-white {
		color: white;
	}

	&-black {
		color: black;
	}

	&-yellow {
		color: $yellow;
	}

	&-dark-grey {
		color: $darkGrey;
	}
}


.background {
	&-white {
		background-color: white;
	}

	&-black {
		background-color: black;
	}

	&-grey {
		background-color: $grey;
	}

	&-dark {
		background-color: $dark;
	}

	&-yellow {
		background-color: $yellow;
	}

	&-very-light-grey {
		background-color: $veryLightGrey;
	}

}


.link {
	&-white {
		@include links(white,
		darken(white, 10%))
	}

	&-yellow {
		font-weight: bold;
		@include links($yellow,
		darken($yellow, 10%))
	}

	&-black {
		@include links(black,
		lighten(black, 10%))
	}

	&-dark-grey {
		@include links($darkGrey,
		lighten($darkGrey, 10%))
	}
}

.hr {
	&-black {
		border: 0;
		border-bottom: 1px solid black;
	}
}