.scroll-down-bttn {
	display: block;

	.img-wrap {
		width: 40px;
		height: 125px;
		display: flex;
		justify-content: center;
	}
}

.bttn {
	display: inline-flex;
	min-width: 200px;
	height: 40px;
	justify-content: center;
	align-items: center;
	font-weight: 500;
	text-transform: uppercase;

	&--bigger {
		min-width: 225px;

		@include respondTo(screenxlgmax) {
			min-width: 200px;
		}
	}

	&--yellow {
		@include buttons($dark,
		$yellow,
		$yellow,
		$dark)
	}

	;

	&--transparent {
		@include buttons(white,
		$dark,
		transparent,
		$yellow);
		border: 1px solid $yellow;
	}
}

.hamburger {
	position: absolute;
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-indent: -9999px;
	overflow: hidden;
	@include square(25px);

	span {
		position: absolute;
	}

	@include transitions;

	i {
		@include transitions;
		display: block;
		width: 25px;
		height: 0;
		background-color: white;
		border: 1px solid white;
		margin-top: 2px;
		margin-bottom: 2px;
	}

	&.is-open {
		i {
			position: absolute;
			top: 40%;
			&:nth-last-child(1) {
				transform: rotate(45deg);
			}
			&:nth-last-child(2) {
				transform: rotate(-45deg);
			}
			&:nth-last-child(3) {
				display: none;
			}
		}
	}
}