* {
    margin: 0;
    padding: 0;
}

html, body {
    width: 100%;
    overflow-x: hidden;
}
body {
    overflow-x: hidden;
    line-height: 1.3;

    font: {
        family: $fontFamily;
        size: 1rem;
        weight:300;
    }

    @include respondTo(screensmmax){
        font-size: .8rem
    }

    @include respondTo(screenxxlgmin){
        font-size: 1rem
    }

    b{
        font-weight: 500;
    }

    &.is-fixed {
        overflow: hidden;
    }
}
a {
    text-decoration: none;   
}
.clearfix {
    float: none;
    clear: both;
}


.text-center{
    text-align: center; 
}
.uppercase{
    text-transform: uppercase;
}
a.underlined{
    text-decoration: underline;
}
.backgrounded{
    @extend %backgrounded;
}

//container size was changed according to the clients needs
.big-container {
    width: 100%;
    max-width: 2560px;
    
}


.lh{  
    &-2{

        line-height: 2;
    }
}