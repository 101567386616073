.socials-list,
.footer-list{
	@extend %listNormalize;
}
.footer-list{
	a{
		display: block;
	}
}

.socials-list{
	@extend %listInline;
	display: inline-flex;	
	align-items: center;	
	li{
		position: relative;	
		overflow: hidden;	
		span{		
			position: absolute;	
			bottom: -100px;
		}
	}
}

.list-normal{
	list-style: none;
	line-height: 1.4;
	li{
		position: relative;
		padding-left: 10px;
		&:before{			
			position: absolute;
			display: block;
			content: "";
			@include square(4px);
			border-radius:100%;
			background-color: $dark;
			top:30%;
			left: 0;
		}		
	}
}