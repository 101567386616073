
/* latin */
@font-face {
  font-family: 'Roboto Slab';
  font-style: normal;
  font-weight: 300;
  src: local('Roboto Slab Light'), local('RobotoSlab-Light'), url(https://fonts.gstatic.com/s/robotoslab/v7/BngRUXZYTXPIvIBgJJSb6u9mxLCGwR0.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin */
@font-face {
  font-family: 'Roboto Slab';
  font-style: normal;
  font-weight: 400;
  src: local('Roboto Slab Regular'), local('RobotoSlab-Regular'), url(https://fonts.gstatic.com/s/robotoslab/v7/BngMUXZYTXPIvIBgJJSb6ufN5qU.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin */
@font-face {
  font-family: 'Roboto Slab';
  font-style: normal;
  font-weight: 700;
  src: local('Roboto Slab Bold'), local('RobotoSlab-Bold'), url(https://fonts.gstatic.com/s/robotoslab/v7/BngRUXZYTXPIvIBgJJSb6u92w7CGwR0.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin */
@font-face {
  font-family: 'Yantramanav';
  font-style: normal;
  font-weight: 300;
  src: local('Yantramanav Light'), local('Yantramanav-Light'), url(https://fonts.gstatic.com/s/yantramanav/v3/flUhRqu5zY00QEpyWJYWN59Yf_NeKBM.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin */
@font-face {
  font-family: 'Yantramanav';
  font-style: normal;
  font-weight: 400;
  src: local('Yantramanav Regular'), local('Yantramanav-Regular'), url(https://fonts.gstatic.com/s/yantramanav/v3/flU8Rqu5zY00QEpyWJYWN5fzXeY.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin */
@font-face {
  font-family: 'Yantramanav';
  font-style: normal;
  font-weight: 500;
  src: local('Yantramanav Medium'), local('Yantramanav-Medium'), url(https://fonts.gstatic.com/s/yantramanav/v3/flUhRqu5zY00QEpyWJYWN58AfvNeKBM.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin */
@font-face {
  font-family: 'Yantramanav';
  font-style: normal;
  font-weight: 700;
  src: local('Yantramanav Bold'), local('Yantramanav-Bold'), url(https://fonts.gstatic.com/s/yantramanav/v3/flUhRqu5zY00QEpyWJYWN59IePNeKBM.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'fontello';
  src: url('../font/fontello/fontello.eot?22165170');
  src: url('../font/fontello/fontello.eot?22165170#iefix') format('embedded-opentype'),
       url('../font/fontello/fontello.woff2?22165170') format('woff2'),
       url('../font/fontello/fontello.woff?22165170') format('woff'),
       url('../font/fontello/fontello.ttf?22165170') format('truetype'),
       url('../font/fontello/fontello.svg?22165170#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}

%fontello{
	display: inline-block;
	font : {
		family: "fontello";
		style: normal;
		weight: normal;
		variant: normal;
	}
	speak: none;

	width: 1em;
	line-height: 1em;
	text : {
		decoration: inherit;
		align: center;
		transform: none;
	}
	margin : {
		right: .2em;
		left: .2em;
	}
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}


.icon-facebook:before {  @extend %fontello; content: '\f09a'; }
.icon-instagram:before {  @extend %fontello; content: '\f16d'; }