.history-line-section{
	position: relative;
	overflow-x: auto;
    overflow-y: hidden;
	cursor: url(../img/arrow.png), auto;	
    &+.history-line-after{
    	display: block;    	
    	background-color: $veryLightGrey;    	
    	position: relative;
    	margin-top: -30px;    	
    	height: 30px;
	}
	
}


.history-line{   
    padding-top: 2vw;
    padding-bottom: 2vw;
    @media (min-width:2560){
        padding-top: 56px;
        padding-bottom: 56px;
    }
	display: inline-flex;  
	position: relative;
    width:100%;
    align-items: center;
	.animated {
		@include transitions;
		opacity: 0;
	}
	.activate {
		opacity: 1; 
	}
    &__head{
        padding-left: 4vw;
        padding-right: 2vw;
        @media (min-width:2560){
            padding-left: 112px;
            padding-right: 56px;
        }
    }   
	&__item{
		flex:1;
        float:left;        
	    width:100%;		
	    .history-icon{
			display: block; 
			max-width:  7vw;
            padding: 1vw 0;	
            @media (min-width:2560){
                max-width:  196px;
                padding: 28px 0;	
            }
           &--smaller{
                max-width:  6vw; 
                @media (min-width:2560){
                    max-width:  168px;                   
                }   
            }	
			img{
				max-width:  100%
			}
		}
		.item-body{
			height:100%;
			display: flex;  
			flex-direction: column; 			
			&__main{
				padding: 1.5vw 1.5vw 6vw;
                margin-left: 5.5vw;
                @media (min-width:2560){
                    padding: 42px 42px 168px;
                    margin-left: 154px;
                }
				flex:1;
				border-left: 1px solid $yellow;
			}
			&--invert{			   
                .item-body__main{
                    padding:  5vw 1.5vw 1.5vw; 
                    @media (min-width:2560){
                        padding: 140px 42px 42px;                       
                    }                 
                }
			}
    	}
	}  
}