.intro-logo {
	display: block;
	margin-bottom: 50px;
	@include respondTo(screenmdmin) {
		margin-bottom: 100px;
		text-align: center;
	}

	img {
		width: 70%;
		@include respondTo(screenxsmin) {
			width: 50%;
		}
	}
}

.has-fog {
	position: relative;

	&:after {
		content: "";
		@extend %fullAbs;
		background-color: transparentize($dark, .6);
	}

	&--in {
		position: relative;
		z-index: 1;
	}
}

.iframe-container {
	overflow: hidden;
	padding-top: 56.25%;
	position: relative;

	iframe {
		border: 0;
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;
	}
}


.modal {
	display: none;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	height: 100%;
	z-index: 10;

	@include respondTo(screenmdmax) {
		padding: 75px 45px 0;
		overflow-y: scroll;
		max-height: 100%;
	}

	&__container,
	&__row {
		@include respondTo(screenmdmin) {
			height: 100%;
		}
	}

	&__body {
		max-width: 900px;
		height: 100%;
	}

	.img-side {
		height: 50%;

		@include respondTo(screenmdmin) {
			height: 100%;
		}
	}

	.modal-image {
		width: 100%;
		height: 100%;

		@include respondTo(screenmdmax) {
			min-height: 375px;
		}

		background : {
			size: contain;
			repeat: no-repeat;
			position: center;
		}
	}

	&__close {
		position: absolute;
		top: 20px;
		right: 20px;
		width: 40px;
		max-width: 100%;
	}
}

.modal-overlay {
	display: none;
	width: 100%;
	height: 100%;
	background-color: transparentize($grey, .5);
	position: fixed;
	top: 0;
	left: 0;
	z-index: 9;
}

.preload {
	position: absolute;
	left: -99999999999px;
}